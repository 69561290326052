// // import smoothscroll from 'smoothscroll-polyfill'
//
// //smoothscroll.polyfill()
const today = document.getElementsByClassName('today__wrapper')
if (today) {
    Array.from(today)
        .forEach((children) => {
            if (children) {
                // console.log(scrolledP)
                const todayArrowNodesP = children.getElementsByClassName('today__arrow')
                const todayBackNodesP = children.getElementsByClassName('today__arrow-back')
                const todayNodes = children.getElementsByClassName('today__list')

                if (todayArrowNodesP.length > 0) {
                    let scrolledP = 0
                    const arrowsP = Array.from(todayArrowNodesP)
                    const backsP = Array.from(todayBackNodesP)
                    const pages = Array.from(todayNodes)

                    arrowsP.map((arrow, key) => {
                        arrow.addEventListener('click', () => {

                            // console.log('scrolledP: ' + scrolledP)
                            // console.log('offsetWidth ' + pages[key].offsetWidth)
                            // console.log('scrollWidth ' + pages[key].scrollWidth)
                            if (pages[key].offsetWidth < pages[key].scrollWidth) {
                                scrolledP += pages[key].offsetWidth
                            }

                            pages[key].scroll({
                                top: 0,
                                left: scrolledP,
                                behavior: 'smooth'
                            })

                            // console.log('scrolledP: ' + scrolledP)
                            // console.log('offsetWidth ' + pages[key].offsetWidth)
                            // console.log('scrollWidth ' + pages[key].scrollWidth)
                            if (scrolledP + pages[key].offsetWidth >= pages[key].scrollWidth) {
                                arrow.classList.add('end')
                            }
                            // console.log(scrolledP != 0)
                            if (scrolledP != 0) {
                                todayBackNodesP[0].classList.remove('end')
                            }
                        })

                        arrow.addEventListener('keyup', (e) => {
                            e = e || window.KeyboardEvent
                            if (e.key === 'ArrowRight') {
                                if (pages[key].offsetWidth < pages[key].scrollWidth) {
                                    scrolledP += pages[key].offsetWidth
                                }

                                pages[key].scroll({
                                    top: 0,
                                    left: scrolledP,
                                    behavior: 'smooth'
                                })
                                if (scrolledP + pages[key].offsetWidth >= pages[key].scrollWidth) {
                                    arrow.classList.add('end')
                                }
                                if (scrolledP != 0) {
                                    todayBackNodesP[0].classList.remove('end')
                                }
                            }
                        })
                    })

                    backsP.map((arrow, key) => {
                        arrow.addEventListener('click', () => {
                            console.log(scrolledP)
                            console.log(pages[key].offsetWidth)
                            if (scrolledP > 0) {
                                scrolledP -= pages[key].offsetWidth
                            }

                            pages[key].scroll({
                                top: 0,
                                left: scrolledP,
                                behavior: 'smooth'
                            })

                            if (scrolledP == 0) {
                                arrow.classList.add('end')
                            }
                            todayArrowNodesP[0].classList.remove('end')
                        })

                        arrow.addEventListener('keyup', (e) => {
                            e = e || window.KeyboardEvent
                            console.log(e.key)
                            if (e.key === 'ArrowLeft') {
                                if (scrolledP > 0) {
                                    scrolledP -= pages[key].offsetWidth
                                }

                                pages[key].scroll({
                                    top: 0,
                                    left: scrolledP,
                                    behavior: 'smooth'
                                })

                                if (scrolledP == 0) {
                                    arrow.classList.add('end')
                                }
                                todayArrowNodesP[0].classList.remove('end')
                            }
                        })
                    })
                }
            }
        })
}
